import React from "react";
import Text from "../../components/data-display/text";
import { Link } from "gatsby";
import SEO from "../../components/seo/seo";
import {useMediaQuery} from "@mui/material";
import {Container, useTheme, Grid} from "@mui/material";
import sydeTaskerCategories from "../../content/services.json";
import quickTaskCategories from "../../content/servicesOfQuickTask.json";
import {environment} from "../../environments/quickTaskEnvironemt";
import { isQuickTaskProject, title } from "../../utils/constant.urls";
  
const QuikTaskMainColor = environment.mainColor;
  
const categories = isQuickTaskProject ? quickTaskCategories : sydeTaskerCategories;



const Services = () => {
  const [imgPlace, setImgPlace] = React.useState("");
  const theme = useTheme();
  const styles = {
    link: {
      color: "black",
      transition: "0.1s",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main,
        textDecoration: "underline",
      },
    },
  };
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div style={{background: "white"}}>
      <SEO
        description={"Here are some of our most popular services..."}
        title={`What can you get done on ${title}?`}
      />
      <div
        style={{
          position: "relative",
          paddingTop: xsDown ? "90px" : "150px",
          paddingBottom: "0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        data-scroll={true}
      >
        <Container maxWidth={"xl"} style={{padding: mdDown ? "0 8%" : "0 12%"}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <ul style={{listStyle: "none", paddingLeft: smDown ? "8px" : "0px"}}>
              {categories.map((c) => {
                return (
                  <li key={c.location}>
                    <>
                      {smDown ? (
                        <Grid container>
                          <Grid item md={12} lg={12} xl={12}>
                            <img
                              loading={"lazy"}
                              src={c.image}
                              alt={c.title}
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </Grid>
                          <Grid item md={12} lg={12} xl={12}>
                            <div
                              style={{
                                paddingBottom: "20px",
                                paddingTop: "20px",
                              }}
                            >
                              <Link to={"/services/" + c.location}>
                                <Text
                                  style={{
                                    ...(styles.link as React.CSSProperties),
                                  }}
                                  variant={"h3"}
                                  black
                                >
                                  {c.navTitle}{" "}
                                </Text>
                              </Link>
                            </div>
                            <Text regular variant={"body1"} style={{maxWidth: "600px"}}>
                              {c.description}
                            </Text>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container>
                          <Grid item xs={6} sm={6} style={{}}>
                            <div style={{paddingBottom: "30px"}}>
                              <Link to={"/services/" + c.location}>
                                <Text
                                  style={{
                                    ...(styles.link as React.CSSProperties),
                                  }}
                                  variant={"h3"}
                                  black
                                >
                                  {c.navTitle}{" "}
                                </Text>
                              </Link>
                            </div>
                            <Text regular variant={"body1"} style={{maxWidth: "600px"}}>
                              {c.description}
                            </Text>
                          </Grid>
                          <Grid xs={6} sm={6}>
                            <img
                              loading="lazy"
                              alt={c.title}
                              src={c.image}
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>

                    <div
                      style={{
                        height: "1px",
                        width: "100%",
                        margin: "24px 0",
                        background: "rgb(180,180,180)",
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Services;
